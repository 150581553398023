import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'usehooks-ts';

import { ConsultationDetailProps } from './ConsultationDetail.types';
import { SCREEN_MD } from '../../../../common/constants/mediaQueries';
import { LoadingState } from '../../../../common/state/components';
import { classNames } from '../../../../common/utils/style';
import { DOCTOR_CONSULTATION } from '../../../graphql/queries/consultations';
import { Consultation } from '../../../graphql/types';
import { ConsultationDetailConversationView } from '../ConsultationDetailConversationView';
import { ConsultationDetailHeader } from '../ConsultationDetailHeader';
import ConsultationDetailProblemView from '../ConsultationDetailProblemView';

export const ConsultationDetail = (props: ConsultationDetailProps) => {
  const {
    consultationId,
    consultationListUpdateCallback,
    user,
    icd10CodeSuggestions,
    onSelectConsultation,
    setConsultationListOpen,
    isVisible,
  } = props;
  const { t } = useTranslation();
  const isMdScreen = useMediaQuery(SCREEN_MD);
  const { loading, data, refetch } = useQuery(DOCTOR_CONSULTATION, {
    variables: { consultationId },
    onCompleted: (data) => {
      if (data.doctorConsultation) {
        onSelectConsultation(data.doctorConsultation);
      }
    },
  });

  useEffect(() => {
    refetchConsultationDetail();
  }, [consultationId]);

  const refetchConsultationDetail = async () => {
    await refetch({
      consultationId,
    });
  };

  const consultationUpdateCallback = async (updatedConsultation: Consultation) => {
    consultationListUpdateCallback(updatedConsultation);
    await refetchConsultationDetail();
  };

  return (
    <div
      className={classNames('h-full overflow-y-hidden bg-white', isVisible ? 'block md:flex md:flex-col' : 'hidden')}
    >
      {loading && !data && (
        <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
          <LoadingState message={t('common:collecting_consultation')} />
        </div>
      )}

      {data && (
        <>
          <ConsultationDetailHeader
            user={user}
            consultation={data.doctorConsultation}
            icd10CodeSuggestions={icd10CodeSuggestions}
            onSelectConsultation={onSelectConsultation}
            setConsultationListOpen={setConsultationListOpen}
            consultationUpdateCallback={consultationUpdateCallback}
          />

          <div className="flex-grow md:grid md:grid-cols-2 md:divide-x">
            <div className="overflow-y-auto" style={isMdScreen ? { height: 'calc(100vh - 183px)' } : {}}>
              <ConsultationDetailProblemView consultation={data.doctorConsultation} />
            </div>
            <div className="flex overflow-hidden">
              <ConsultationDetailConversationView
                consultation={data.doctorConsultation}
                user={user}
                consultationUpdateCallback={consultationUpdateCallback}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { InboxPresetSelectorProps } from './InboxPresetSelector.types';
import { UserContext } from '../../../../common/authentication/UserContext';
import { classNames } from '../../../../common/utils/style';
import { InboxPreset } from '../../../graphql/types';

export function InboxPresetSelector(props: InboxPresetSelectorProps) {
  const { user } = useContext(UserContext);
  const { filter, onFilterChange, totalConsultationsCount } = props;
  const { t } = useTranslation();

  const presets = [
    { name: t('common:new'), preset: InboxPreset.New },
    { name: t('common:in_progress'), preset: InboxPreset.InProgress },
    { name: t('common:all'), preset: InboxPreset.All },
  ];

  if (user?.is_medical_care) {
    presets.splice(1, 0, { name: t('common:longest_waiting'), preset: InboxPreset.LongestWaiting });
  }

  function onPresetClick(inboxPreset: InboxPreset): void {
    onFilterChange({
      ...filter,
      inboxPreset,
    });
  }

  return (
    <div>
      <div className="border-t border-b border-gray-200">
        <nav className="-mb-px flex justify-between" aria-label="Tabs">
          {presets.map((preset) => (
            <a
              key={preset.name}
              href="/"
              onClick={(event) => {
                event.preventDefault();
                onPresetClick(preset.preset);
              }}
              className={classNames(
                preset.preset === filter.inboxPreset
                  ? 'border-teal-500 text-teal-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'w-1/3 border-b-2 py-4 px-1 text-center text-xs md:text-sm font-medium'
              )}
              aria-current={preset.preset === filter.inboxPreset ? 'page' : undefined}
            >
              {preset.name}
              {preset.preset === filter.inboxPreset && <span className="ml-1">({totalConsultationsCount})</span>}
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
}
